import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import dataV from '@jiaminghi/data-view'
import elementui from './assets/elementui'
import axios from 'axios'
import {
	postToken,
	getToken
} from './utils/api.js'


Vue.config.productionTip = false
Vue.prototype.$axios = axios
Vue.prototype.$postToken = postToken
Vue.prototype.$getToken = getToken

router.beforeEach((to, from, next) => {
	if (to.path === '/') {
		localStorage.removeItem('userinfo')
		next();
	} else {
		let token = localStorage.getItem('userinfo');
		if (token === null || token === '') {
			next('/');
		} else {
			next();
		}
	}
});

Vue.use(dataV)
new Vue({
	router,
	store,
	render: h => h(App)
}).$mount('#app')