import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
	// 工作台（大屏端）
	{
		path: '/index',
		name: 'Index',
		component: () => import('../views/Index/Index.vue')
	},
	// 工作台（网页端）
	{
		path: '/workbench',
		name: 'Workbench',
		component: () => import('../views/Workbench/Workbench.vue')
	},
	// 电力资源数据
	{
		path: '/power',
		name: 'Power',
		component: () => import('../views/Power/Power.vue')
	}, {
		// 生产线呢数据
		path: '/productionline',
		name: 'Productionline',
		component: () => import('../views/Productionline/Productionline.vue')
	}, {
		// 出入库数据
		path: '/receiptIssue',
		name: 'ReceiptIssue',
		component: () => import('../views/ReceiptIssue/ReceiptIssue.vue')
	}, {
		// 地磅实时数据
		path: '/weighbridge',
		name: 'Weighbridge',
		component: () => import('../views/Weighbridge/Weighbridge.vue')
	}, {
		// 实时监控
		path: '/realtimejk',
		name: 'Realtimejk',
		component: () => import('../views/Realtimejk/Realtimejk.vue')
	}, {
		// 登录
		path: '/',
		name: 'Login',
		component: () => import('../views/Login/Login.vue')
	}

]






const router = new VueRouter({
	mode: 'hash',
	base: process.env.BASE_URL,
	routes
})



export default router