import axios from 'axios'
const api = 'http://jtsy.hbjtjsjl.cn/api/'
// const api = 'http://qyjtsy.keanzhitong.com/api/'
// post
export const postToken = (url,data,token= 'bcbefb5c7606577a0f6c30398a5bfabf')=>{
	return axios({
		method:'post',
		url:api+url,
		data:data,
		headers:{
			'csrftoken': token
		}
	})
}
// get 
export const getToken = (url,data,token= 'bcbefb5c7606577a0f6c30398a5bfabf')=>{
	return axios({
		method:'get',
		url:api+url,
		data:data,
		headers:{
			'csrftoken':token
		}
	})
}